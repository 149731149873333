import React, { useState, useEffect } from "react";
import { withRouter, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { Turivius } from "../../ui";
import { Button, Col, Grid, Row } from "rsuite";
import { requester } from "../../requesters";

//Components
import { NavCompany } from "../../components/CustomNav";
import CreateNewUserButton from "../../components/CreateNewUserButton";
import { UpdateStatusCompanyButton } from "../../components/UpdateStatusButton";
import CompanyDetails from "../../components/CompanyInformations/CompanyDetails";
import CompanyAccount from "../../components/CompanyInformations/CompanyAccount";
import BulkCreateButton from "../../components/BulkCreateButton";
import CompanyUsers from "../../components/CompanyInformations/CompanyUsers";
import CompanyPaymentSettings from "../../components/CompanyInformations/CompanyPaymentSettings";

//Config
import "./style.scss";
import TuriviusColors from "../../vars.module.scss";

const Company = (props) => {
  const params = useParams();
  const [load, setLoad] = useState(true);
  const [active, setActive] = React.useState("company");
  const [company, setCompany] = React.useState([]);

  const [hasMore, setHasMore] = useState(true);
  const [pageUsers, setPageUser] = useState(1);
  const [loadUsers, setLoadUsers] = useState(false);

  const [users, setUsers] = useState(null);
  const [usersCount, setUsersCount] = useState(0);

  function goBack() {
    props.history.goBack();
  }

  function getCompany() {
    requester.admin
      .getCompanyById(params.companyId)
      .then((response) => {
        document.title = `${response.name}`;
        setCompany(response);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => setLoad(false));
  }

  function getUsersByCompany() {
    setUsers(null);
    requester.admin
      .getUsersByCompany({ companyId: company.id, page: pageUsers })
      .then((response) => {
        setUsers(response.results);
        setUsersCount(response.count);
        setHasMore(response.next ? true : false);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function loadMore() {
    let page,
      user = users;

    page = pageUsers + 1;
    setPageUser(page);
    setLoadUsers(true);

    requester.admin
      .getUsersByCompany({ companyId: company.id, page: page })
      .then((response) => {
        Object.keys(response.results).map((item) => {
          user.push(response.results[item]);
        });

        // setUsers([...users, user]);
        setHasMore(response.next ? true : false);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => setLoadUsers(false));
  }

  useEffect(() => {
    getCompany();
  }, []);
  return load ? (
    <Turivius.TuriviusPreloader />
  ) : (
    <div
      className="container-company"
      style={{
        height: "100vh",
        overflowY: "auto",
        paddingTop: 0,
      }}
    >
      <Grid fluid>
        <Row
          style={{
            position: "sticky",
            top: 0,
            backgroundColor: "#fff",
            zIndex: 6,
            paddingTop: 40,
          }}
        >
          <Row>
            <Col xs={active === "users" ? 11 : 15}>
              <Button
                style={{
                  padding: 0,
                  backgroundColor: "transparent",
                  marginBottom: "5px",
                }}
                onClick={() => goBack()}
              >
                <FontAwesomeIcon
                  color={TuriviusColors.turivius_light}
                  size="2x"
                  icon={faArrowLeft}
                />
              </Button>
              <h3>{company.name}</h3>
              <p
                className={`type-client ${company.asaas_url ? "client" : "trial"}`}
              >
                {company.asaas_url ? "Cliente" : "Trial"}
              </p>
            </Col>

            {active === "company" ? (
              <Col xs={4} xsPush={5}>
                <UpdateStatusCompanyButton data={company} />
              </Col>
            ) : active === "users" ? (
              <>
                <Col xs={4} xsPush={5}>
                  <BulkCreateButton
                    requiredFields={["nome", "email", "posicao"]}  // Campos obrigatórios
                    fieldFormats={{
                      email: "email",   // Valida que seja um e-mail válido
                      posicao: "string", // Valida que seja uma string
                      plano: "string",    // Valida que seja uma string
                      phone: "string",
                      
                    }}
                    company={company}
                  />
                </Col>
                <Col xs={4} xsPush={5}>
                  <CreateNewUserButton
                    company={company}
                    getUsersByCompany={getUsersByCompany}
                  />
                </Col>
              </>
            ) : (
              <Col xs={2} xsPush={12}>
                {/* <ExportDataButton company={company} users={users} getUsersByCompany={getUsersByCompany}/> */}
              </Col>
            )}
          </Row>

          <Row className="company-menu">
            <NavCompany
              appearance="subtle"
              index={props}
              active={active}
              setActive={setActive}
              onSelect={setActive}
              tabDefault={active}
            />
          </Row>
        </Row>

        <Row>
          {active === "company" ? (
            <CompanyDetails data={company} getCompany={getCompany} />
          ) : active === "users" ? (
            <CompanyUsers
              companyId={company.id}
              company={company}
              users={users}
              usersCount={usersCount}
              getUsersByCompany={getUsersByCompany}
              loadMore={loadMore}
              hasMore={hasMore}
              loadMoreUsers={loadUsers}
            />
          ) : active === "account" ? (
            <CompanyAccount data={company} />
          ) : (
            <CompanyPaymentSettings data={company} getCompany={getCompany} />
          )}
        </Row>
      </Grid>
    </div>
  );
};

export default withRouter(Company);
