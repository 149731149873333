import React, { useEffect } from "react";
import {
  Col,
  InputNumber,
  Row,
  Tooltip,
  Whisper,
  Toggle,
  Button,
  MultiCascader,
} from "rsuite";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faXmark } from "@fortawesome/free-solid-svg-icons";

const TrabalhistaPermissions = ({
  permissions,
  permissionKey,
  entities,
  changePermissionValue,
  onChangeEntities,
  groupArray,
  onChangeObject,
  updateUserServiceSegmentation,
  loadSave,
}) => {
  return (
    <div>
      <Row>
        <p className="config-session-title">Permissões básicas</p>
        <Col xs={24} sm={24} md={16}>
          <label className="config-session-subtitle">Qtdd Pesquisas</label>
          <br />
          <Whisper
            trigger="focus"
            placement="autoHorizontal"
            speaker={<Tooltip>Valor 0 significa quantidade ilimitada</Tooltip>}
          >
            <InputNumber
              min={0}
              value={permissions?.qt_pesquisas ?? ""}
              onChange={(value) =>
                changePermissionValue(permissionKey, "qt_pesquisas", value)
              }
            />
          </Whisper>
        </Col>
      </Row>

      <Row>
        <p className="config-session-title">Órgãos</p>
        <Col xs={24} sm={24} md={16}>
          <label className="config-session-subtitle">Órgãos da Pesquisa</label>
          <br />
          <MultiCascader
            value={permissions?.entidades}
            block
            placeholder="Selecione os órgãos"
            data={groupArray(entities, "class")}
            onChange={(value) =>
              onChangeEntities(permissionKey, "entidades", value)
            }
            columnWidth={200}
          />
          {/* <small>Copiar de: </small>
                <small className="clickable-selection disable" onClick={() => onChangeEntities(permissionKey, "entidades", permissions?.orgaos_monitorados)}>Órgãos do Monitoramento</small>
                <b> | </b>
                <small className="clickable-selection disable" onClick={() => onChangeEntities(permissionKey, "entidades", permissions?.dataviz_entidades)}>Órgãos da Jurimetria</small> */}
        </Col>
      </Row>

      <Row>
        <p className="config-session-title">Jurimetria (Casos de Uso)</p>
        <Col xs={24} sm={12} md={8}>
          <label className="config-session-subtitle">Por CNPJ</label>
          <br />
          <Toggle
            checked={permissions?.jurimetria?.cnpj}
            size="lg"
            checkedChildren={<FontAwesomeIcon icon={faCheck} />}
            unCheckedChildren={<FontAwesomeIcon icon={faXmark} />}
            onChange={(value) =>
              onChangeObject(permissionKey, "jurimetria", "cnpj", value)
            }
          />
        </Col>
        <Col xs={24} sm={12} md={8}>
          <label className="config-session-subtitle">Por Estado</label>
          <br />
          <Toggle
            checked={permissions?.jurimetria?.estado}
            size="lg"
            checkedChildren={<FontAwesomeIcon icon={faCheck} />}
            unCheckedChildren={<FontAwesomeIcon icon={faXmark} />}
            onChange={(value) =>
              onChangeObject(permissionKey, "jurimetria", "estado", value)
            }
          />
        </Col>
        <Col xs={24} sm={12} md={8}>
          <label className="config-session-subtitle">Por Assunto</label>
          <br />
          <Toggle
            checked={permissions?.jurimetria?.assunto}
            size="lg"
            checkedChildren={<FontAwesomeIcon icon={faCheck} />}
            unCheckedChildren={<FontAwesomeIcon icon={faXmark} />}
            onChange={(value) =>
              onChangeObject(permissionKey, "jurimetria", "assunto", value)
            }
          />
        </Col>
      </Row>

      <Row>
        <p className="config-session-title">Nestlé Business Dashboard</p>
        <Col xs={24} sm={12} md={8}>
          <label className="config-session-subtitle">Área Empresarial</label>
          <br />
          <Toggle
            checked={permissions?.business_dashboard?.area_empresarial}
            size="lg"
            checkedChildren={<FontAwesomeIcon icon={faCheck} />}
            unCheckedChildren={<FontAwesomeIcon icon={faXmark} />}
            onChange={(value) =>
              onChangeObject(
                permissionKey,
                "business_dashboard",
                "area_empresarial",
                value,
              )
            }
          />
        </Col>
      </Row>

      <Row>
        <p className="config-session-title">Filtros</p>
        <Col xs={24} sm={12} md={8}>
          <label className="config-session-subtitle">Filtro órgãos</label>
          <br />
          <Toggle
            size="lg"
            checked={permissions?.filtros?.entity}
            checkedChildren={<FontAwesomeIcon icon={faCheck} />}
            unCheckedChildren={<FontAwesomeIcon icon={faXmark} />}
            onChange={(value) =>
              onChangeObject(permissionKey, "filtros", "entity", value)
            }
          />
        </Col>
        <Col xs={24} sm={12} md={8}>
          <label className="config-session-subtitle">Ordenação</label>
          <br />
          <Toggle
            size="lg"
            checked={permissions?.filtros?.sort}
            checkedChildren={<FontAwesomeIcon icon={faCheck} />}
            unCheckedChildren={<FontAwesomeIcon icon={faXmark} />}
            onChange={(value) =>
              onChangeObject(permissionKey, "filtros", "sort", value)
            }
          />
        </Col>
        <Col xs={24} sm={12} md={8}>
          <label className="config-session-subtitle">Filtro por data</label>
          <br />
          <Toggle
            size="lg"
            checked={permissions?.filtros?.date_range}
            checkedChildren={<FontAwesomeIcon icon={faCheck} />}
            unCheckedChildren={<FontAwesomeIcon icon={faXmark} />}
            onChange={(value) =>
              onChangeObject(permissionKey, "filtros", "date_range", value)
            }
          />
        </Col>
        {/* <Col xs={24} sm={12} md={8}>
                <label className="config-session-subtitle">Filtro de resultado da decisão</label><br />
                <Toggle
                    size="lg"
                    checked={permissions?.filtros?.filter_by_favorability}
                    checkedChildren={<FontAwesomeIcon icon={faCheck} />}
                    unCheckedChildren={<FontAwesomeIcon icon={faXmark} />}
                    onChange={(value) => onChangeObject(permissionKey, "filtros", "filter_by_favorability", value)}
                />
            </Col> */}
        <Col xs={24} sm={12} md={8}>
          <label className="config-session-subtitle">
            Filtro de tipo da decisão
          </label>
          <br />
          <Toggle
            size="lg"
            checked={permissions?.filtros?.filter_by_vote}
            checkedChildren={<FontAwesomeIcon icon={faCheck} />}
            unCheckedChildren={<FontAwesomeIcon icon={faXmark} />}
            onChange={(value) =>
              onChangeObject(permissionKey, "filtros", "filter_by_vote", value)
            }
          />
        </Col>
        <Col xs={24} sm={12} md={8}>
          <label className="config-session-subtitle">Filtro por relator</label>
          <br />
          <Toggle
            size="lg"
            checked={permissions?.filtros?.filter_by_relator}
            checkedChildren={<FontAwesomeIcon icon={faCheck} />}
            unCheckedChildren={<FontAwesomeIcon icon={faXmark} />}
            onChange={(value) =>
              onChangeObject(
                permissionKey,
                "filtros",
                "filter_by_relator",
                value,
              )
            }
          />
        </Col>
        {/* <Col xs={24} sm={12} md={8}>
                <label className="config-session-subtitle">Filtro por seção (CARF)</label><br />
                <Toggle
                    size="lg"
                    checked={permissions?.filtros?.filter_by_orgao_julgador_stf}
                    checkedChildren={<FontAwesomeIcon icon={faCheck} />}
                    unCheckedChildren={<FontAwesomeIcon icon={faXmark} />}
                    onChange={(value) => onChangeObject(permissionKey, "filtros", "filter_by_orgao_julgador_stf", value)}
                />
            </Col> */}
        <Col xs={24} sm={12} md={8}>
          <label className="config-session-subtitle">
            Filtro por órgão julgador (somente STF)
          </label>
          <br />
          <Toggle
            size="lg"
            checked={permissions?.filtros?.filter_by_orgao_julgador_stf}
            checkedChildren={<FontAwesomeIcon icon={faCheck} />}
            unCheckedChildren={<FontAwesomeIcon icon={faXmark} />}
            onChange={(value) =>
              onChangeObject(
                permissionKey,
                "filtros",
                "filter_by_orgao_julgador_stf",
                value,
              )
            }
          />
        </Col>
      </Row>

      <Row>
        <Col md={4} mdOffset={20}>
          <Button
            block
            className="btn-save"
            loading={loadSave}
            onClick={() => updateUserServiceSegmentation(permissionKey)}
            appearance="primary"
          >
            Salvar
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default TrabalhistaPermissions;
