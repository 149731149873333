import React, { useState, useEffect } from "react";
import { withRouter, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faGear } from "@fortawesome/free-solid-svg-icons";
import { Turivius } from "../../ui";
import { Button, Grid, Row, Col, Dropdown } from "rsuite";
import { requester } from "../../requesters";

//Components
import { NavUsers } from "../../components/CustomNav";
import { UpdateStatusUserButton } from "../../components/UpdateStatusButton";
import ResendButton from "../../components/ResendButton";
import UserDetails from "../../components/UserInformations/UserDetails";
import UserMonitoring from "../../components/UserInformations/UserMonitoring";
import UserColletions from "../../components/UserInformations/UserCollections";
import UserPermissions from "../../components/UserInformations/UserPermissions";
import UserHistorySearch from "../../components/UserInformations/UserHistorySearch";
import NewPasswordButton from "../../components/NewPasswordButton";
import DeleteUserSessionsButton from "../../components/DeleteUserSessionsButton";

//Config
import TuriviusColors from "../../vars.module.scss";
import "./style.scss";

const renderToggleMenu = (props, ref) => {
  return (
    <Button {...props} ref={ref} className="btn-action">
      <FontAwesomeIcon icon={faGear} />
      Opções
    </Button>
  );
};

const tabsMenus = [
  {
    eventKey: "details",
    href: "#details",
    name: "Dados",
  },
  {
    eventKey: "permissions",
    href: "#permissions",
    name: "Permissões",
  },
  {
    eventKey: "researches",
    href: "#researches",
    name: "Pesquisas",
  },
  {
    eventKey: "collections",
    href: "#collections",
    name: "Coleções",
  },
  {
    eventKey: "monitoring",
    href: "#monitoring",
    name: "Monitoramento",
  },
];

const User = (props) => {
  const params = useParams();
  const [load, setLoad] = useState(true);
  const [selectMenu, setSelectMenu] = useState("details");
  const [user, setUser] = useState(null);
  const [additionals, setAdditionals] = useState(null);
  const [entities, setEntities] = useState([]);

  function goBack() {
    props.history.goBack();
  }

  async function getEntities() {
    let entity = [];

    await requester.cards
      .getEntities()
      .then((res) => {
        Object.keys(res).map((e) => {
          entity.push(res[e]);
        });
      })
      .catch(console.error);

    await requester.cards
      .getEntities("trabalhista")
      .then((res) => {
        Object.keys(res).map((e) => {
          entity.push(res[e]);
        });
      })
      .catch(console.error);

    setEntities(entity);
  }

  function getUserDetails() {
    requester.admin
      .getUserById(params.userId)
      .then((response) => {
        document.title = `${response.id} - ${response.name}`;
        setUser(response);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        getAdditional();
      });
  }

  const getAdditional = () => {
    requester.profiles
      .getOnboardingUserById(params.userId)
      .then((res) => {
        setAdditionals({
          occupation_area: res.occupation_area,
          performs_jurisprudential_search:
            null,
        });
      })
      .catch(() => console.log)
      .finally(() => {
        setLoad(false);
      });
  };

  useEffect(() => {
    getUserDetails();
    // getAdditional()
    getEntities();
  }, []);

  return load ? (
    <Turivius.TuriviusPreloader />
  ) : (
    <div
      className="container-user"
      style={{
        height: "100vh",
        overflowY: "auto",
        paddingTop: 0,
      }}
    >
      <Grid fluid>
        <Row
          style={{
            position: "sticky",
            top: 0,
            backgroundColor: "#fff",
            zIndex: 6,
            paddingTop: 40,
          }}
        >
          <Row>
            <Col sm={4}>
              <Button
                style={{
                  padding: 0,
                  backgroundColor: "transparent",
                  marginBottom: "5px",
                }}
                onClick={() => goBack()}
              >
                <FontAwesomeIcon
                  color={TuriviusColors.turivius_light}
                  size="2x"
                  icon={faArrowLeft}
                />
              </Button>
            </Col>
            <Col sm={20}>
              <div
                style={{ display: "flex", justifyContent: "flex-end", gap: 5 }}
              >
                {/* <div className="monitoring-menu"> */}
                <UpdateStatusUserButton data={user} />
                <Dropdown
                  placement="bottomEnd"
                  renderToggle={renderToggleMenu}
                  id="nav-menu-options"
                >
                  <Dropdown.Item eventKey={1}>
                    <ResendButton data={user} />
                  </Dropdown.Item>
                  <Dropdown.Item eventKey={2}>
                    <NewPasswordButton email={user.email} />
                  </Dropdown.Item>
                  <Dropdown.Item eventKey={3}>
                    <DeleteUserSessionsButton userId={user.id} />
                  </Dropdown.Item>
                </Dropdown>
                {/* </div> */}
              </div>
            </Col>
          </Row>
          <Row>
            <h2>{user.name}</h2>
            <span className="user-name">{user.company.name}</span>
          </Row>
        </Row>

        <Row className="user-menu">
          <NavUsers
            index={props}
            appearance="subtle"
            tabsData={tabsMenus}
            active={selectMenu}
            onSelect={setSelectMenu}
            tabDefault={selectMenu}
          />
        </Row>

        <Row>
          {selectMenu === "details" ? (
            <UserDetails
              data={user}
              additionals={additionals}
              getUserDetails={getUserDetails}
            />
          ) : selectMenu === "permissions" ? (
            <UserPermissions
              page="user"
              user={user}
              getUserDetails={getUserDetails}
            />
          ) : selectMenu === "researches" ? (
            <UserHistorySearch user={user.id} userName={user.name} />
          ) : selectMenu === "collections" ? (
            <UserColletions user={user.id} />
          ) : (
            <UserMonitoring user={user.id} entities={entities} />
          )}
        </Row>
      </Grid>
    </div>
  );
};

export default withRouter(User);
