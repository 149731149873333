import React, { useState, useRef } from "react";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Turivius } from "../../ui";
import { Button, Form, Message, Modal, Schema, toaster } from "rsuite";
import TuriviusColors from "../../vars.module.scss";
import "./style.scss";
import { requester } from "../../requesters";

const model = Schema.Model({
  name: Schema.Types.StringType().isRequired("Este campo é obrigatório."),
  email: Schema.Types.StringType()
    .isEmail("Por favor, informe um e-mail válido.")
    .isRequired("Este campo é obrigatório."),
  position: Schema.Types.StringType().isRequired("Este campo é obrigatório."),
});

const CreateNewUserButton = ({ getUsersByCompany, ...props }) => {
  const formRef = useRef();
  const [openModal, setOpenModal] = useState(false);
  const [formError, setFormError] = useState({});
  const [formValue, setFormValue] = useState({});
  const [loading, setLoading] = useState(false);

  function handleModal() {
    setOpenModal(!openModal);
  }

  const handleSubmit = () => {
    if (!formRef.current.check()) {
      console.error("Form Error");
      setLoading(false)
      return;
    }

    setLoading(true);
    requester.admin
      .createUser(
        props.company.id,
        props.company.name,
        props.company.cellphone,
        formValue.email,
        formValue.name,
        formValue.position,
      )
      .then((response) => {
        toaster.push(
          <Message closable duration={4000} type="success">
            Usuário criado com sucesso!
          </Message>,
        );
        getUsersByCompany();
        handleModal();
      })
      .catch((err) => {
        console.log(err);
        toaster.push(
          <Message closable duration={4000} type="error">
            Erro ao criar usuário: {err.message}
          </Message>,
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      {props.isBody ? (
        <Turivius.TuriviusButton
          backgroundColor={TuriviusColors.green}
          color={TuriviusColors.white}
          className="btn-action-user body"
          onClick={() => handleModal()}
        >
          Criar Usuário
        </Turivius.TuriviusButton>
      ) : (
        <Turivius.TuriviusIconButton
          fullWidth
          backgroundColor={TuriviusColors.green}
          color={TuriviusColors.white}
          icon={<FontAwesomeIcon className="rs-icon" icon={faPlus} />}
          className="btn-action-user"
          onClick={() => handleModal()}
        >
          Criar Usuário
        </Turivius.TuriviusIconButton>
      )}

      <Modal open={openModal} onClose={handleModal} size="md" backdrop="static">
        <Modal.Header>
          <h3>Novo Usuário</h3>
        </Modal.Header>

        <Modal.Body>
          <Form
            fluid
            ref={formRef}
            onChange={setFormValue}
            onCheck={setFormError}
            formValue={formValue}
            formError={formError}
            model={model}
            readOnly={loading}
          >
            <Form.Group controlId="name">
              <Form.ControlLabel>Nome</Form.ControlLabel>
              <Form.Control name="name" />
            </Form.Group>
            <Form.Group controlId="email">
              <Form.ControlLabel>E-mail</Form.ControlLabel>
              <Form.Control name="email" />
            </Form.Group>
            <Form.Group controlId="position">
              <Form.ControlLabel>Cargo</Form.ControlLabel>
              <Form.Control name="position" />
            </Form.Group>
            <Form.Group controlId="company">
              <Form.ControlLabel>Empresa</Form.ControlLabel>
              <Form.Control
                name="company"
                value={props.company?.name}
                plaintext
              />
            </Form.Group>
          </Form>
        </Modal.Body>

        <Modal.Footer>
          <Button readOnly={loading} onClick={() => handleModal()}>
            Cancelar
          </Button>
          <Button
            loading={loading}
            onClick={() => handleSubmit()}
            appearance="primary"
          >
            Criar Usuário
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CreateNewUserButton;
