import React from "react";
import PropTypes from "prop-types";
import { Table, Pagination } from "rsuite";
import "./TuriviusTable.scss";

const CustomCell = ({ rowData, dataKey, customCell, ...props }) => (
  <Table.Cell {...props}>
    {customCell({ rowData, dataKey, customCell, ...props })}
  </Table.Cell>
);

const TuriviusTable = ({
  autoHeight,
  bordered,
  cellBordered,
  columns,
  data,
  hover,
  loading,
  locale,
  minHeight,
  onRowClick,
  rowClassName,
  rowHeight,
  showHeader,
  width,
  height,
  wordWrap,
  style,
  paginate,
  first,
  last,
  next,
  prev,
  defaultActivePage,
  maxButtons,
  total,
  limit,
  onChangePage,
  paginationStyle,
  activePage,
}) => {
  const [defaultActivePages, setDefaultActivePages] = React.useState(
    defaultActivePage || 1
  );
  const [sortColumn, setSortColumn] = React.useState(null);
  const [sortType, setSortType] = React.useState(null);

  // Função para ordenar os dados
  const sortedData = React.useMemo(() => {
    if (!sortColumn || !sortType) return data;

    return [...data].sort((a, b) => {
      const valueA = a[sortColumn];
      const valueB = b[sortColumn];

      if (typeof valueA === "number" && typeof valueB === "number") {
        return sortType === "asc" ? valueA - valueB : valueB - valueA;
      }

      return sortType === "asc"
        ? String(valueA).localeCompare(String(valueB))
        : String(valueB).localeCompare(String(valueA));
    });
  }, [data, sortColumn, sortType]);

  // Função para lidar com a mudança de ordenação
  const handleSortColumn = (column, type) => {
    setSortColumn(column);
    setSortType(type);
  };

  return (
    <div>
      <Table
        autoHeight={autoHeight}
        bordered={bordered}
        cellBordered={cellBordered}
        data={sortedData}
        hover={hover}
        loading={loading}
        locale={locale}
        minHeight={minHeight}
        onRowClick={onRowClick}
        rowClassName={rowClassName}
        rowHeight={rowHeight}
        showHeader={showHeader}
        width={width}
        height={height}
        wordWrap={wordWrap}
        style={style}
        sortColumn={sortColumn}
        sortType={sortType}
        onSortColumn={handleSortColumn}
      >
        {columns?.map((column) => {
          const { key, label, ...rest } = column;
          return (
            <Table.Column {...rest} key={key} sortable>
              {column.customHeader ? (
                <column.customHeader>{label}</column.customHeader>
              ) : (
                <Table.HeaderCell>{label}</Table.HeaderCell>
              )}
              {column.customCell ? (
                <CustomCell dataKey={key} customCell={column.customCell} />
              ) : (
                <Table.Cell dataKey={key} />
              )}
            </Table.Column>
          );
        })}
      </Table>
      {paginate ? (
        <Pagination
          total={total}
          limit={limit}
          first={first}
          last={last}
          next={next}
          prev={prev}
          maxButtons={maxButtons}
          activePage={activePage || defaultActivePages}
          onChangePage={(page) => {
            setDefaultActivePages(page);
            onChangePage(page);
          }}
          style={paginationStyle || {}}
        />
      ) : null}
    </div>
  );
};

TuriviusTable.propTypes = {
  autoHeight: PropTypes.bool,
  bordered: PropTypes.bool,
  cellBordered: PropTypes.bool,
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  hover: PropTypes.bool,
  loading: PropTypes.bool,
  locale: PropTypes.shape({
    emptyMessage: PropTypes.string,
    loading: PropTypes.string,
  }),
  minHeight: PropTypes.number,
  onRowClick: PropTypes.func,
  rowClassName: PropTypes.string,
  rowHeight: PropTypes.number,
  showHeader: PropTypes.bool,
  width: PropTypes.number,
  height: PropTypes.number,
  wordWrap: PropTypes.bool,
  style: PropTypes.object,

  paginate: PropTypes.bool,
  first: PropTypes.bool,
  last: PropTypes.bool,
  next: PropTypes.bool,
  prev: PropTypes.bool,
  defaultActivePages: PropTypes.number,
  maxButtons: PropTypes.number,
  total: PropTypes.number,
  limit: PropTypes.number,
  onChangePage: PropTypes.func,
  paginationStyle: PropTypes.object,
};

TuriviusTable.defaultProps = {
  data: [],
  columns: [],
  onRowClick: () => {},
  locale: {
    emptyMessage: "Nenhum dado encontrado",
    loading: "Carregando...",
  },

  paginate: false,
  first: true,
  last: true,
  next: true,
  prev: true,
  defaultActivePage: 1,
  maxButtons: 5,
  total: 100,
  limit: 10,
  onChangePage: () => {},
};

export default TuriviusTable;
