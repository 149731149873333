import React, { useState, useEffect } from "react";
import { Route, Switch, withRouter, Redirect } from "react-router-dom";
import { requester } from "../../requesters";
import { CustomProvider } from "rsuite";
import { Provider } from "react-redux";
import store from "../../store";
import ptBR from "rsuite/locales/pt_BR";

// Components
import TuriviusBasePage from "../TuriviusBasePage";
import TuriviusAcademy from "../../pages/TuriviusAcademy/TuriviusAcademy";
import PrivateRoute from "./PrivateRoute";
import Companies from "../../pages/Companies";
import Company from "../../pages/Company";
import EntitiesPage from "../../pages/Entities";
import Users from "../../pages/Users";
import User from "../../pages/User";
import NotFound from "../../pages/NotFound";
import Logout from "../../pages/Logout";
import Login from "../../pages/Login";
import "./App.scss";

requester.setUserFinger("samantha");
requester.init(localStorage.getItem("token"), {
  webservice: process.env.REACT_APP_WEBSERVICE,
  cards: process.env.REACT_APP_CARDS,
  collections: process.env.REACT_APP_COLLECTIONS,
  jurimetrics: process.env.REACT_APP_JURIMETRICS,
  profiles: process.env.REACT_APP_PROFILES,
  help: process.env.REACT_APP_HELP,
  notifications: process.env.REACT_APP_NOTIFICATIONS,
  notifications_ws: process.env.REACT_APP_NOTIFICATIONS_WS,
  search_notifications: process.env.REACT_APP_SEARCH_NOTIFICATIONS,
  segmentation: process.env.REACT_APP_SEGMENTATION,
  monitoring: process.env.REACT_APP_MONITORING,
  rdstation: process.env.REACT_APP_RDSTATION,
  reports: process.env.REACT_APP_REPORTS,
  dashboards: process.env.REACT_APP_DASHBOARDS,
});

const App = () => {
  const token = localStorage.getItem("token");

  // Estados para controle de visibilidade do logo
  const [isVisible, setIsVisible] = useState(true);

  // Controla o tempo de exibição do logo e aplica transição
  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(false);
    }, 5000); // 5 segundos

    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <div className={`loading-container ${isVisible ? "visible" : "hidden"}`}>
        <img src="/logoZionCompleto.png" alt="Logo Zion" className="loading-logo" />
      </div>

      <Provider store={store}>
        <div>
          <Route exact path="/">
            {token ? <Redirect to="/empresas" /> : <Redirect to="/login" />}
          </Route>
          <CustomProvider locale={ptBR}>
            <TuriviusBasePage>
              <Switch>
                <Route path="/login" component={Login} />
                <PrivateRoute path="/empresas/:companyId/" component={Company} />
                <PrivateRoute path="/empresas" component={Companies} />
                <PrivateRoute path="/usuarios/:userId/" component={User} />
                <PrivateRoute path="/usuarios" component={Users} />
                <PrivateRoute path="/entities" component={EntitiesPage} />
                <PrivateRoute
                  path="/turivius-academy"
                  component={TuriviusAcademy}
                />
                <Route path="/logout" component={Logout} />
                <Route component={NotFound} />
              </Switch>
            </TuriviusBasePage>
          </CustomProvider>
        </div>
      </Provider>
    </>
  );
};

export default withRouter(App);
