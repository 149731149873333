import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRightFromBracket,
  faBuilding,
  faBuildingUser,
  faGraduationCap,
  faSignOutAlt,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import { withRouter } from "react-router-dom";
import TuriviusSideMenu from "../TuriviusSideMenu";
import styles from "../../vars.module.scss";  // Apenas para uso dentro do SCSS
class TuriviusSidenav extends React.Component {
  constructor(props) {
    super(props);
    this.notificationWrapper = React.createRef();
  }

  state = {
    activeKey: null,
    showNavbar: true,
    profile: null,
  };

  handleSelect = (eventKey) => {
    this.setState({
      activeKey: eventKey,
    });
  };

  updateNavBar = (pathname) => {
    if (pathname === "/login") {
      this.setState({ showSearch: false, showNavbar: false });
    } else {
      this.setState({ showSearch: true, showNavbar: true });
    }
  };

  componentDidMount() {
    this.mounted = true;
    this.setState({ profile: localStorage.getItem("profile") });
    window.addEventListener("resize", this.updateDimensions);
    this.updateNavBar(this.props.history.location.pathname);
    this.props.history.listen((location) => {
      if (this.mounted) {
        this.updateNavBar(location.pathname);
      }
    });
  }

  render() {
    const { activeKey, showNavbar } = this.state;

    const profile = localStorage.getItem("profile")
      ? JSON.parse(localStorage.getItem("profile"))
      : null;

    const header = {
      img: "/logoZionCompleto.png",
      onClick: () => this.props.history.push(`/usuarios/${profile?.id}`),
    };

    const navItemsA = [
      {
        icon: (
          <FontAwesomeIcon
            icon={faBuildingUser}
            style={{ fontSize: 20, color: styles.sidebar_icon, marginRight: 12 }}
          />
        ),
        children: "Administrador",
        empty: {
          name: "Clique aqui para ativar!",
          onClick: () => console.log("teste"),
          show: false,
        },
        products: [
          {
            name: "Empresas",
            route: "/empresas",
            onClick: () => this.props.history.push("/empresas"),
            show: true,
          },
          {
            name: "Usuários",
            route: "/usuarios",
            onClick: () => this.props.history.push("/usuarios"),
            show: true,
          },
          {
            name: "Tribunais",
            route: "/entities",
            onClick: () => this.props.history.push("/entities"),
            show: true,
            soon: false,
          },
          {
            name: "Planos",
            route: "/planos",
            onClick: () => this.props.history.push("/planos"),
            show: true,
            soon: true,
          },
        ],
      },
      {
        icon: (
          <FontAwesomeIcon
            icon={faGraduationCap}
            style={{ fontSize: 20, color: styles.sidebar_icon, marginRight: 12 }}
          />
        ),
        children: "Turivius Academy",
        route: "/turivius-academy",
        onClick: () => this.props.history.push("/turivius-academy"),
        empty: {
          name: "Clique aqui para ativar!",
          onClick: () => console.log("teste"),
          show: false,
        },

        products: [],
      },
    ];

    const footerA = [
      {
        icon: (
          <FontAwesomeIcon
            icon={faArrowRightFromBracket}
            style={{ fontSize: 20, color: styles.sidebar_icon, marginRight: 12 }}
          />
        ),
        children: "Sair",
        onClick: () => this.props.history.push("/logout"),
        show: true,
      },
    ];

    return (
      <TuriviusSideMenu
        show={showNavbar}
        footer={footerA}
        navItems={navItemsA}
        defaultActiveKey={activeKey}
        header={header}
      />
    );
  }
}

export default withRouter(TuriviusSidenav);
