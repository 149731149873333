import {
  faAngleDown,
  faBars,
  faTimes,
  faLock
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Button, Dropdown, Nav, Sidebar, Sidenav } from "rsuite";
import "./style.scss";
import styles from "../../vars.module.scss"; 

const NewSidenav = ({
  defaultOpenKeys,
  handleSelectNavItem,
  header,
  footer,
  show,
  navItems,
}) => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [routeActive, setRouteActive] = useState(false);
  const { pathname } = useLocation();

  useEffect(() => {
    setRouteActive(pathname);
  }, [pathname]);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isCollapsed]);

  const handleResize = () => {
    setIsCollapsed(window.detectMob);
  };

  return (
    <>
      {show ? (
        !isCollapsed ? (
          <>
            <div className="contente-sidebar">
              <Sidebar className="turivius-sidebar">
                <Sidenav
                  defaultOpenKeys={defaultOpenKeys}
                  expanded={true}
                  onSelect={handleSelectNavItem}
                  className="turivius-sidenav"
                >
                  {isOpen ? (
                    <Button onClick={() => setIsCollapsed(!isCollapsed)}>
                      <i className="ri-arrow-left-circle-line sidenav-header_icon" />
                    </Button>
                  ) : null}
                  <Sidenav.Header>
                    <Button
                      onClick={() => header.onClick()}
                      className="sidenav-header_logo"
                    >
                      <img
                        src={header.img}
                        alt="Logo Turivius"
                        style={{ width: 250 }}
                      />
                    </Button>
                  </Sidenav.Header>
                  <Sidenav.Body>
                    <div className="sidenav-body">
                      {navItems.map((item, index) => {
                        return (
                          <div>
                            <div
                              className={`turivius-module`}
                              onClick={item.onClick}
                            >
                              {item.icon}
                              <p
                                className={`turivius-module_name ${item?.route === routeActive ? "active" : null}`}
                              >
                                {item.children}
                              </p>
                            </div>
                            <Nav>
                              {item.products.length > 0
                                ? item.products.map((i, index) => {
                                  return i.show ? (
                                    <Nav.Item
                                      key={index}
                                      onClick={() => {
                                        if (!i?.soon) {
                                          i.onClick();
                                        }
                                      }}
                                      className={i?.route === routeActive ? "turivius-menu_option" : ""}
                                      style={{
                                        cursor: i?.soon ? "not-allowed" : "pointer",
                                        opacity: i?.soon ? 0.5 : 1, // Diminui a opacidade para indicar bloqueio
                                      }}
                                    >
                                      {i?.soon && (
                                        <FontAwesomeIcon icon={faLock} style={{ marginRight: 8 }} />
                                      )}
                                      {i.name}
                                    </Nav.Item>
                                  ) : null;
                                })
                                : null}
                            </Nav>
                          </div>
                        );
                      })}
                    </div>
                    <div className="sidenav-footer">
                      <Nav className="turivius-module">
                        {footer?.map((item, index) => {
                          return (
                            <Nav.Item
                              key={index}
                              onClick={() => {
                                item.onClick();
                              }}
                              className="nav-item_footer"
                            >
                              {item.icon}
                              <p className="turivius-module_name">
                                {item.children}
                              </p>
                            </Nav.Item>
                          );
                        })}
                      </Nav>
                    </div>
                  </Sidenav.Body>
                </Sidenav>
              </Sidebar>
            </div>
            <div
              style={{
                textAlign: "center",
                color: "#ccc",
                padding: "10px 0 30px",
                position: "absolute",
                bottom: 0,
                width: 260,
                backgroundColor: styles.sidebar_medium,
              }}
            >
              {/* <div className="flag-system"> */}
              <p style={{ color: styles.sidebar_icon, marginBottom: -5 }}>from</p>
              <img
                style={{ cursor: "pointer", width: 150, filter:'invert(53%) sepia(14%) saturate(99%) hue-rotate(0deg) brightness(99%) contrast(99%)'  }}
                onClick={() =>
                  window.open("https://app.turivius.com/", "_blank")
                }
                src="/new_logo-white.png"

              />
              {/* </div> */}
            </div>
          </>
        ) : (
          <div className="sidebar-collapsed">
            <Button
              onClick={() => {
                setIsOpen(true);
                setIsCollapsed(!isCollapsed);
              }}
            >
              <FontAwesomeIcon icon={faBars} />
            </Button>
          </div>
        )
      ) : null}
    </>
  );
};
export default NewSidenav;
