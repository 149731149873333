import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock, faLockOpen, faCheck, faXmark } from "@fortawesome/free-solid-svg-icons";
import { Button, Grid, Row, Col, Input, Dropdown } from "rsuite";
import { Turivius } from "../../ui";
import { requester } from "../../requesters";
import { withRouter } from "react-router-dom";
import * as XLSX from "xlsx";
import "./styles.scss"; // Importando o CSS

const infos = {
    columns: [
        // { key: "name", label: "Nome", fixed: false, width: 300, resizable: true },
        { key: "initials", label: "Sigla", fixed: false, width: 150, resizable: true },
        { key: "jurisdiction", label: "Jurisdição", fixed: false, width: 150, resizable: true },
        {
            key: "doc_count_filtered",
            label: "Qtd na Turivius",
            fixed: false, width: 180, resizable: true,
            customCell: ({ rowData }) => <p>{rowData.doc_count_filtered || 0}</p>,
        },
        {
            key: "count_source",
            label: "Qtd na Fonte",
            fixed: false, width: 180, resizable: true,
            customCell: ({ rowData }) => (
                <p>{rowData?.count_source || 'Não encontrado'}</p>
            ),
        },
        {
            key: "last_entry_dt",
            label: "Última Captura de dados",
            fixed: false, width: 280, resizable: true,
            customCell: ({ rowData }) => (
                <p>{rowData.last_entry_dt ? new Date(rowData.last_entry_dt).toLocaleDateString("pt-BR") : "Sem dados"}</p>
            ),
        },
        {
            key: "sumarizable",
            label: "Resumo IA ?",
            fixed: false, width: 150, resizable: true,
            customCell: ({ rowData }) => <p>{<FontAwesomeIcon
                className="rs-icon"
                icon={rowData.sumarizable ? faCheck : faXmark}
            />}</p>,
        },

    ],
};

const EntitiesPage = (props) => {
    const [entities, setEntities] = useState([]); // Conjunto total de dados
    const [filteredEntities, setFilteredEntities] = useState([]); // Valores exibidos na tabela
    const [loading, setLoading] = useState(true);
    const [search, setSearch] = useState(""); // Filtro de pesquisa por nome/sigla
    const [dateFilter, setDateFilter] = useState(null); // Período de atualização
    const [dataPresenceFilter, setDataPresenceFilter] = useState("sim"); // Teve ou não dados
    const pageSize = 100; // Total de elementos na tabela
    const [activePage, setActivePage] = useState(1); // controle de página ativa do componente (fora da tabela)
    const [currentTotal, setCurrentTotal] = useState(0); // Total de elementos (Com filtro)

    // estado do filtro de data
    const [selectedDateFilter, setSelectedDateFilter] = useState("Todos");
    const [selectedDataPresence, setSelectedDataPresence] = useState("Sim");

    // estado do resumo de IA
    const [aiSummaryFilter, setAiSummaryFilter] = useState(null);
    const [selectedAiSummary, setSelectedAiSummary] = useState("Todos");

    // estado do filtro de jurisdição
    const [jurisdictionFilter, setJurisdictionFilter] = useState(null);
    const [selectedJurisdiction, setSelectedJurisdiction] = useState("Todas");

    // Aplica um dos períodos fixos
    const applyDateFilter = (days) => {
        if (!days) {
            // Se os dias não foram informados ele reseta o filtro
            setDateFilter(null)
        } else {
            const today = new Date();
            const startDate = new Date();
            startDate.setDate(today.getDate() - days);
            setDateFilter([startDate, today]);
        }

    };



    const exportToExcel = () => {
        const ws = XLSX.utils.json_to_sheet(filteredEntities);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Entidades");

        XLSX.writeFile(wb, "entidades.xlsx");
    };

    const changeGenericFilter = () => {
        setLoading(true);
        changePage(1);
        setLoading(false);
    };

    function keyPressSearch(e) {
        if (e.key === "Enter") {
            changeGenericFilter();
        }
    }

    useEffect(() => {
        requester.cards
            .getCrawlersInfo({})
            .then((data) => {
                setEntities(data);
                setLoading(false);
                setCurrentTotal(data.length);
            })
            .catch((error) => {
                console.error("Erro ao buscar tribunais:", error);
                setLoading(false);
            });
    }, []);

    // se qualquer filtro for mudado ele aciona e remapeia os dados
    useEffect(() => {
        changePage(1); // essa parte troca a página atual (no caso aplica os filtros já que não tem mais páginação)
    }, [entities, dateFilter, dataPresenceFilter, aiSummaryFilter, jurisdictionFilter]); 

    const changePage = (page) => {
        const [start, end] = [(page - 1) * pageSize, page * pageSize];

        // Primeiro filtra pelos dados que estão na barra de busca
        let filteredSearch = entities.filter(
            (entity) =>
                entity.name.toLowerCase().includes(search.toLowerCase()) ||
                entity.initials.toLowerCase().includes(search.toLowerCase())
        );

        // filtrar os que tem resumo de IA
        if (aiSummaryFilter !== null) {
            filteredSearch = filteredSearch.filter(entity => entity.sumarizable === aiSummaryFilter);
        }

        // filtrar por jurisdição
        if (jurisdictionFilter !== null) {
            filteredSearch = filteredSearch.filter(entity => entity.jurisdiction === jurisdictionFilter);
        }

        // Filtrar se teve ou não dados e a partir de qual data
        if (dateFilter && dataPresenceFilter !== null) {
            const thresholdDate = dateFilter[0]

            // Se ele quer dados que foram capturados
            if (dataPresenceFilter === "sim") {

                // Filtro aqueles que são maiores que a base da data
                filteredSearch = filteredSearch.filter((entity) => {
                    const entryDate = new Date(entity.last_entry_dt);
                    return entryDate >= thresholdDate;
                });

            } else if (dataPresenceFilter === "nao") {

                // Filtro aqueles que tem a data da ultima captura menor que a base da data
                filteredSearch = filteredSearch.filter((entity) => {
                    const entryDate = new Date(entity.last_entry_dt);
                    return entryDate < thresholdDate || isNaN(entryDate);
                });
            }
        }



        setFilteredEntities(filteredSearch.slice(start, end));
        setActivePage(page);
        setCurrentTotal(filteredSearch.length);
    };

    useEffect(() => {
        document.title = `Entidades`;
    }, []);

    return (
        <Grid fluid>
            <div className="container-entities">
                <Row className="entities-header">
                    <Col xs={24}>
                        <h1>Entidades</h1>
                    </Col>
                </Row>

                {/* Filtros */}
                <Row className="entities-filters">
                    {/* Filtro por presença de dados */}
                    <Col xs={24} sm={12} md={4}>
                        <div>
                            <p><strong>Foram Capturados:</strong></p>
                            <Dropdown title={selectedDataPresence}>
                                <Dropdown.Item onSelect={() => { setDataPresenceFilter("sim"); setSelectedDataPresence("Sim"); }}>
                                    Sim
                                </Dropdown.Item>
                                <Dropdown.Item onSelect={() => { setDataPresenceFilter("nao"); setSelectedDataPresence("Não"); }}>
                                    Não
                                </Dropdown.Item>
                            </Dropdown>
                        </div>
                    </Col>

                    {/* Filtro de data */}
                    <Col xs={24} sm={10} md={4}>
                        <div>
                            <p><strong>Em qual data:</strong></p>
                            <Dropdown title={selectedDateFilter}>
                                <Dropdown.Item onSelect={() => { applyDateFilter(null); setSelectedDateFilter("Todos"); }}>
                                    Todos
                                </Dropdown.Item>
                                <Dropdown.Item onSelect={() => { applyDateFilter(1); setSelectedDateFilter("Ontem"); }}>
                                    Ontem
                                </Dropdown.Item>
                                <Dropdown.Item onSelect={() => { applyDateFilter(3); setSelectedDateFilter("Últimos 3 dias"); }}>
                                    Últimos 3 dias
                                </Dropdown.Item>
                                <Dropdown.Item onSelect={() => { applyDateFilter(7); setSelectedDateFilter("Última semana"); }}>
                                    Última semana
                                </Dropdown.Item>
                                <Dropdown.Item onSelect={() => { applyDateFilter(15); setSelectedDateFilter("Últimas 2 semanas"); }}>
                                    Últimas 2 semanas
                                </Dropdown.Item>
                                <Dropdown.Item onSelect={() => { applyDateFilter(30); setSelectedDateFilter("Últimos 30 dias"); }}>
                                    Últimos 30 dias
                                </Dropdown.Item>
                                <Dropdown.Item onSelect={() => { applyDateFilter(90); setSelectedDateFilter("Últimos 90 dias"); }}>
                                    Últimos 90 dias
                                </Dropdown.Item>

                            </Dropdown>
                        </div>

                    </Col>


                    <Col xs={24} sm={10} md={3}>
                        <div>
                            <p><strong>Resumo de IA:</strong></p>
                            <Dropdown title={selectedAiSummary}>
                                <Dropdown.Item onSelect={() => { setAiSummaryFilter(null); setSelectedAiSummary("Todos"); }}>
                                    Todos
                                </Dropdown.Item>
                                <Dropdown.Item onSelect={() => { setAiSummaryFilter(true); setSelectedAiSummary("Com Resumo"); }}>
                                    Com Resumo
                                </Dropdown.Item>
                                <Dropdown.Item onSelect={() => { setAiSummaryFilter(false); setSelectedAiSummary("Sem Resumo"); }}>
                                    Sem Resumo
                                </Dropdown.Item>
                            </Dropdown>
                        </div>
                    </Col>

                    <Col xs={24} sm={4} md={3}>
                        <div>
                            <p><strong>Jurisdição:</strong></p>
                            <Dropdown title={selectedJurisdiction}>
                                <Dropdown.Item onSelect={() => { setJurisdictionFilter(null); setSelectedJurisdiction("Todas"); }}>
                                    Todas
                                </Dropdown.Item>
                                {Array.from(new Set(entities.map(entity => entity.jurisdiction))) // Obtém valores únicos
                                    .map(jurisdiction => (
                                        <Dropdown.Item
                                            key={jurisdiction}
                                            onSelect={() => { setJurisdictionFilter(jurisdiction); setSelectedJurisdiction(jurisdiction); }}
                                        >
                                            {jurisdiction}
                                        </Dropdown.Item>
                                    ))}
                            </Dropdown>
                        </div>
                    </Col>


                    {/* Filtro de pesquisa textual */}
                    <Col xs={24} sm={12} md={3}>
                        <p><strong>Busca Textual</strong></p>
                        <Input
                            placeholder="Filtrar por nome"
                            className="input-search"
                            value={search}
                            onChange={setSearch}
                            onKeyUp={keyPressSearch}
                        />
                    </Col>

                    <Col xs={24} sm={12} md={3}>
                        <Button block appearance="success" className="button-search" loading={loading} onClick={changeGenericFilter}>
                            Buscar
                        </Button>
                    </Col>
                    
                    <Col xs={24} sm={12} md={3}>
                        <Button block className="button-export" onClick={exportToExcel}>
                            Exportar para Excel
                        </Button>
                    </Col>
                </Row>

                {/* Tabela de Entidades */}
                <div className="entities-body">
                    <Turivius.TuriviusTable
                        data={filteredEntities}
                        columns={infos.columns}
                        loading={loading}
                        height={550}
                        fixed
                        wordWrap
                        // paginate
                        onChangePage={(page) => changePage(page)}
                        total={currentTotal}
                        activePage={activePage}

                    />
                </div>
            </div>
        </Grid>
    );
};

export default withRouter(EntitiesPage);
