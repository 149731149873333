import { faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import { withRouter } from "react-router-dom";
import {
  Grid,
  Col,
  Row,
  SelectPicker,
  Input,
  Button,
  InputGroup,
  Form,
  Schema,
  CheckPicker,
  toaster,
  Message,
} from "rsuite";
import { requester } from "../../../requesters";
import {
  areas,
  occupation_area,
  performsJurisprudentialSearch,
} from "../../../config/companies";
import ChangeOffice from "../../ChangeOfficeButton";
import "./style.scss";

const model = Schema.Model({
  name: Schema.Types.StringType().isRequired("Este campo é obrigatório."),
  email: Schema.Types.StringType()
    .isEmail("Informe um e-mail válido")
    .isRequired("Este campo é obrigatório."),
});

const UserDetails = ({ data, getUserDetails, additionals, ...props }) => {
  const formRef = useRef();
  const [formError, setFormError] = useState({});
  const [formValue, setFormValue] = useState({});
  const [loading, setLoading] = useState(false);
  const [edit, setEdit] = useState(false);

  const handleSubmit = () => {
    setLoading(true);

    if (!formRef.current.check()) {
      console.error("Form Error");
      return;
    }

    let performs_jurisprudential_search = null
    try{
      performs_jurisprudential_search = JSON.parse(
        formValue?.performs_jurisprudential_search,
      )
    }
    catch{

    }


    let requestData = {
      email: formValue.email,
      linkedin_link: formValue?.linkedin_link,
      name: formValue?.name,
      position: formValue?.position,
      short_bio: formValue?.short_bio,
      law_area: formValue?.law_area,
      occupation_area: formValue?.occupation_area,
      performs_jurisprudential_search: null,
      phone: formValue?.phone,
    };

    requester.admin
      .updateUser(data.id, requestData)
      .then((response) => {
        getUserDetails();
        setEdit(false);

        toaster.push(
          <Message closable type="success">
            Usuário atualizado com sucesso!
          </Message>,
        );

        return requester.admin.getUserById(data.id);
      }).catch((err) => {
        console.log(err);
        toaster.push(
          <Message
            closable
            duration={5000}
            type="error"
            header={`Erro ${err?.status}`}
          >
            {err?.message}
          </Message>,
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  function setDefaultValues() {
    setEdit(false);

    setFormValue({
      id: data.id,
      name: data.name,
      email: data.email,
      short_bio: data.short_bio,
      company_name: data.company.name,
      position: data.position,
      linkedin_link: data.linkedin_link,
      created_at: new Date(data.created).toLocaleDateString("pt-BR"),
    });
  }

  useEffect(() => {
    let defaultValue = {
      ...data,
      created_at: new Date(data.created).toLocaleDateString("pt-BR"),
      company_name: data.company.name,
      occupation_area: additionals?.occupation_area || '',
      performs_jurisprudential_search: null,
    };
    setFormValue(defaultValue);
  }, []);

  return (
    <Grid fluid className="grid-details-container">
      <Form
        fluid
        ref={formRef}
        onChange={setFormValue}
        onCheck={setFormError}
        formValue={formValue}
        formError={formError}
        model={model}
        readOnly={loading}
      >
        <Row style={{ marginTop: 15 }}>
          <Col xs={24} sm={24} md={3}>
            <Form.Group controlId="id">
              <Form.ControlLabel>ID</Form.ControlLabel>
              <Form.Control name="id" disabled />
            </Form.Group>
          </Col>
          <Col xs={24} sm={24} md={7}>
            <Form.Group controlId="name">
              <Form.ControlLabel>Nome</Form.ControlLabel>
              <Form.Control name="name" disabled={!edit} />
            </Form.Group>
          </Col>
          <Col xs={24} sm={24} md={7}>
            <Form.Group controlId="email">
              <Form.ControlLabel>E-mail</Form.ControlLabel>
              <Form.Control name="email" disabled={!edit} />
            </Form.Group>
          </Col>
          <Col xs={24} sm={24} md={7}>
            <Form.Group controlId="short_bio">
              <Form.ControlLabel>Descrição</Form.ControlLabel>
              <Form.Control name="short_bio" disabled={!edit} />
            </Form.Group>
          </Col>
        </Row>

        <Row style={{ marginTop: 15 }}>
          <Col xs={24} sm={24} md={8}>
            <Form.Group controlId="company_name">
              <Form.ControlLabel>
                Escritório
                <Button
                  className="btn-icon-company"
                  onClick={() =>
                    props.history.push(`/empresas/${data.company.id}`)
                  }
                >
                  <FontAwesomeIcon
                    className="icon-company"
                    icon={faArrowUpRightFromSquare}
                  />
                </Button>
              </Form.ControlLabel>
              <InputGroup style={{ width: "100%" }}>
                <Input readOnly value={data.company.name} disabled />
                <ChangeOffice
                  disabled={!edit}
                  companyId={data.company.id}
                  userId={data.id}
                />
              </InputGroup>
            </Form.Group>
          </Col>
          <Col xs={24} sm={24} md={8}>
            <Form.Group controlId="position">
              <Form.ControlLabel>Cargo</Form.ControlLabel>
              <Form.Control name="position" disabled={!edit} />
            </Form.Group>
          </Col>
          <Col xs={24} sm={24} md={8}>
            <Form.Group controlId="phone">
              <Form.ControlLabel>Telefone</Form.ControlLabel>
              <Form.Control name="phone" disabled={!edit} />
            </Form.Group>
          </Col>
        </Row>

        <Row style={{ marginTop: 15 }}>
          <Col xs={24} sm={24} md={8}>
            <Form.Group controlId="law_area">
              <Form.ControlLabel>Áreas do Direito</Form.ControlLabel>
              {/* <Form.Control name="law_area" /> */}
              <Form.Control
                name="law_area"
                accepter={CheckPicker}
                data={areas}
                searchable={false}
                disabled={!edit}
                block
              />
            </Form.Group>
          </Col>
          <Col xs={24} sm={24} md={8}>
            <Form.Group controlId="law_area">
              <Form.ControlLabel>Área de Ocupação</Form.ControlLabel>
              {/* <Form.Control name="law_area" /> */}
              <Form.Control
                name="occupation_area"
                accepter={SelectPicker}
                data={occupation_area}
                searchable={false}
                disabled={!edit}
                block
              />
            </Form.Group>
          </Col>
          <Col xs={24} sm={24} md={8}>
            <Form.Group controlId="performs_jurisprudential_search">
              <Form.ControlLabel>Buscas Jurisprudenciais</Form.ControlLabel>
              <Form.Control
                name="performs_jurisprudential_search"
                accepter={SelectPicker}
                data={performsJurisprudentialSearch}
                searchable={false}
                disabled={!edit}
                block
              />
            </Form.Group>
          </Col>
        </Row>

        <Row style={{ marginTop: 15 }}>
          <Col xs={24} sm={24} md={8}>
            <Form.Group controlId="last_modified">
              <Form.ControlLabel>Última Atualização</Form.ControlLabel>
              <Form.Control disabled readOnly name="last_modified" />
            </Form.Group>
          </Col>

          <Col xs={24} sm={24} md={8}>
            <Form.Group controlId="last_modified">
              <Form.ControlLabel>Data de Criação</Form.ControlLabel>
              <Form.Control disabled readOnly name="created_at" />
            </Form.Group>
          </Col>
        </Row>
      </Form>
      <Row style={{ marginTop: 20 }}>
        <Col xs={24} sm={24}>
          {edit ? (
            <Button
              loading={loading}
              onClick={handleSubmit}
              className="btn-save"
              style={{ float: "right" }}
            >
              Salvar
            </Button>
          ) : (
            <Button
              loading={loading}
              onClick={() => setEdit(true)}
              className="btn-edit"
              style={{ float: "right" }}
            >
              Editar
            </Button>
          )}
          {edit ? (
            <Button
              appearance="ghost"
              onClick={setDefaultValues}
              className="btn-edit-cancel"
              style={{ float: "right", marginRight: 5 }}
            >
              Cancelar
            </Button>
          ) : null}
        </Col>
      </Row>
    </Grid>
  );
};

export default withRouter(UserDetails);
